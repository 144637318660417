<template>
  <sm-editable-item
    v-model="form"
    customParamsInDependentLinks
    showConfirmDependentLinkForValidatedForm
    :controllerName="controllerName"
    :isLoading="isLoadingPage"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    :isEqual="isEqual"
    :disabledSaveButton="disabledSaveButton"
    :isLoadingSaveButton="isLoadingSaveButton"
    :dependentLinks="dependentLinks"
    @save="onSaveForm()"
    @cancel="onCancel('EmploymentCalendar')"
    @confirmClickingOnTheDependentLink="onConfirmClickingOnTheDependentLink($event)"
  />
</template>

<script>
// vuex
import { mapActions, mapState } from 'vuex';
// mixins
import editableListItemEdit from '@/mixins/editableListItemEdit.js';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'EmploymentCalendarEdit',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemEdit],

  data() {
    return {
      isLoadingPage: false,
      controllerName: 'EmploymentCalendar',
      breadcrumbs: [
        {
          text: 'Колл-центр',
          route: { name: 'CallCenterMain' },
        },
        {
          text: 'График работы сотрудников',
          route: { name: 'EmploymentCalendar' },
        },
        {
          text: 'Редактирование календаря занятости сотрудников',
        },
      ],
      pageHeader: 'Редактирование календаря занятости сотрудников',
      form: {
        id: null,
        period: '',
      },
    };
  },

  computed: {
    ...mapState({
      item: (state) => state.editableList.item,
    }),

    disabledSaveButton() {
      return !this.form.period;
    },

    fields() {
      return [
        {
          form: [
            {
              type: 'date',
              key: 'period',
              label: 'Период',
              attributes: {
                type: 'month',
                format: 'MM.YYYY',
                valueType: 'YYYYMM',
                timeTitleFormat: 'MM.YYYY',
              },
              disabled: true,
            },
          ],
        },
      ];
    },

    dependentLinks() {
      const { id } = this.form;

      const links = [];

      if (id) {
        links.push({
          text: 'График работы сотрудника',
          route: {
            name: 'EmploymentCalendarGraphEdit',
            params: { id },
          },
        });
      }

      if (!links.length) return null;
      return links;
    },

    formIsValid() {
      const { id } = this.form;

      if (!id) return false;

      return true;
    },
  },

  created() {
    this.isLoadingPage = true;
  
    this.getItem({
      name: this.controllerName,
      id: this.id,
    }).then(() => {
      const { id, period } = this.lodash.cloneDeep(this.item.data);
      this.form.id = id;
      this.form.period = period + '';
      this.isLoadingPage = false;
    });
  },

  methods: {
    ...mapActions({
      getItem: 'editableList/getItem',
      updateItem: 'editableList/updateItem',
    }),

    onSaveForm() {
      if (this.isEqual || !this.formIsValid) return;

      this.onSave('EmploymentCalendar');
    },

    onConfirmClickingOnTheDependentLink(route) {
      if (this.isEqual || !this.formIsValid) {
        this.$notify({
          header: 'Ошибка при сохоанении формы',
          text: 'Заполнены не все обязательные поля',
          type: 'error',
          timer: 10000,
        });

        return;
      }
      this.isLoadingSaveButton = true;
      this.updateItem({
        controllerName: this.controllerName,
        payload: this.form,
      })
        .then((result) => {
          if (!result.isSucceed) return;
          this.$router.push(route);
        })
        .finally(() => {
          this.isLoadingSaveButton = false;
        });
    },
  },
};
</script>
